<template>
  <div>
    <div class="col-lg-12">
      <div class="breadcrumb-trail breadcrumbs">
        <ul class="trail-items breadcrumb">
          <li class="trail-item trail-begin">
            <router-link :to="{ name: 'Home' }" class="footer-a"
                      >Inicio</router-link>
          </li>
          <li class="trail-item">
            <router-link :to="{ name: 'Store' }" class="footer-a">Tienda</router-link>
          </li>
          <li class="trail-item trail-end active">
            {{item.name}}
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div
        class="content-area content-details full-width col-lg-9 col-md-8 col-sm-12 col-xs-12"
      >
        <div class="site-main">
          <MainDetailsProduct />
          <DetailsProduct :item="item"/>
          <div style="clear: left;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DetailsProduct from "./product/DetailsProduct.vue";
import MainDetailsProduct from "./product/MainDetailsProduct.vue";
import { mapGetters } from "vuex";

export default {
  name: "Product",
  components: {
    DetailsProduct,
    MainDetailsProduct,
  },
  computed: {
    ...mapGetters({
      item: "ecommerce/product"
    }),
  },
};
</script>

<style></style>
