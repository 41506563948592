<template>
  <div class="details-product">
    <div class="details-thumd">
      <DetailsProductSlide :slides="product.images.map((e)=>imageUrl(e))" />
    </div>
    <div class="details-infor">
      <h1 class="product-title">{{ product.name }}</h1>
      <div class="availability" v-if="product.code!==null">
        Codigo # {{product.code}}
      </div>

      <div v-if="configuration.show_stock==1" class="availability">
        Stock:
        <a :class="stockClass()">{{stockStatus()}}</a>
      </div>

      <template v-if="showPrice">
        <div v-if="configuration.show_pricelists==0" class="price">
          <span>${{ (product.prices!== undefined) ? product.prices[0] : '' }}</span>
        </div>
        <div v-else>
          Minoristas: <span class="price">$ {{ (product.prices!== undefined) ? product.prices[0] : '' }}</span><br>
          Mayoristas: <span class="price">$ {{ (product.prices!== undefined) ? product.prices[1] : '' }}</span><br>
        </div>
        <br v-if="configuration.show_pricelists==1">
      </template>
      <ProductGroupButton />
    </div>
  </div>
</template>

<script>
import DetailsProductSlide from "./DetailsProductSlide.vue";
import { mapActions, mapGetters } from "vuex";
import ProductGroupButton from "./ProductGroupButton";

export default {
  name: "MainDetailsProduct",
  props: {
    useModal: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object
    }
  },
  data() {
    return {
      itemId: this.$route.params.id,
      instock:'',
      producto: null
    };
  },
  components: {
    DetailsProductSlide,
    ProductGroupButton
  },
  computed: {
    ...mapGetters({
      configuration: "ecommerce/configuration",
      product: "ecommerce/product",
      user: "ecommerce/user"
    }),
    showPrice: {
      get: function () {
        if (this.configuration.show_public_prices) {
            return true;
        } else {
          if (this.user!=null) {
            return true;
          }
        }
        return false;
      }
    },
  },
  mounted() {
    this.loadProduct(this.itemId);
    console.log(this.itemId);

    if (this.useModal) {
      this.producto = this.item
    } else {
      this.producto = this.product
    }
  },
  methods: {
    imageUrl(image) {
      const arr = image.split('.');
      const ext = arr.pop();
      return arr.join('.')+'_detail.'+ext
    },
    ...mapActions({
      loadProduct: 'ecommerce/loadProduct'
    }),
    stockStatus(){
      if (this.product.stock>0) {
        return 'Disponible';
      } else {
        return 'No disponible'
      }
    },
    stockClass() {
      if (this.product.stock > 0) {
        return 'stock-available'; // Clase para disponible (verde)
      } else {
        return 'stock-unavailable'; // Clase para no disponible (rojo)
      }
    }
  },

};
</script>

<style scoped>
.stock-available {
  background-color: green;
  color: white !important;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
}

.stock-unavailable {
  background-color: red;
  color: white !important;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.splide__list{ will-change:auto !important; }
.leaflet-fade-anim .leaflet-tile,.leaflet-zoom-anim .leaflet-zoom-animated { will-change:auto !important; }

.quantity-add-to-cart {
  margin-top: 2em;
}

.single_add_to_cart_button:hover {
  background-color: var(--accent-color-dark);
}
.single_add_to_cart_button:active {
  background-color: var(--accent-color-light);
}
</style>
<!--
Para la tienda de Qupro usa el objecto "product" que viene por mapGetters.

Para el catalogo generado usa la prop "item" que a su vez se guarda en el objeto "producto".

Si hago andar un campo en Catalogo se rompe en Tienda, y viceversa.

No puedo sobreescribir "product" con lo que hay en "item" porque Vue se queja.

Si tengo dudas y quiero deshacer los cambios para no romper la Tienda, todo lo del DOM 
que se llama "producto" antes se llamaba "product".

UPDATE: Dejé todo linkeado a "product" para que ande bien en Tienda.
Para probar en Catalogo, cambiar instancias del DOM de "product" a "producto".
-->
